











import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
} from "@vue/composition-api";

export default defineComponent({
  components: {
    OHeader: () => import("@/components/organisms/o-header.vue"),
    OSidebar: () => import("@/components/organisms/o-sidebar.vue"),
    OSpeedDial: () => import("@/components/organisms/o-speed-dial.vue"),
    OTosDialog: () => import("@/components/organisms/o-tos-dialog.vue"),
  },

  setup(_, { root }) {
    const state = reactive({
      navigation: root.$vuetify.breakpoint.lgAndUp,
    });

    onMounted(() => root.$store.dispatch("contact/startTimer"));
    onBeforeUnmount(() => root.$store.dispatch("contact/stopTimer"));

    return { state };
  },
});
